import { initFlowbite } from "flowbite";

window.addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render;

  event.detail.render = function (streamElement) {
    originalRender(streamElement);
    initFlowbite();
  };
});
